// Variables
@import 'vars';


$start_deg:   0deg;
$middle_deg:  900deg;
$finish_deg:  1800deg;

// Styles
#spinner_block {
  @media #{$desktop-device}{
    float: left;
    width: 698px;
    padding-bottom: 15px;
    position: relative;
  }
  & .spinner {
    width: #{$spinner-width};
    height: #{$spinner-height};
    @media #{$mobile-device}{
      width: #{$spinner-width-mob};
      height: #{$spinner-height-mob};
    }
    margin: 5px auto;
    & .ellipse31 {
      position: relative;
      width: $spinner-width;
      height: $spinner-height;
      @media #{$mobile-device}{
        width: $spinner-width-mob;
        height: $spinner-height-mob;
      }
      border: 10px solid #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
      border-radius: 500px;
      overflow: hidden;
      & .group58 {
        width: $spinner-width;
        height: $spinner-height;
        @media #{$mobile-device}{
          width: $spinner-width-mob;
          height: $spinner-height-mob;
        }
        opacity: 0.8;
        & .group {
          position: relative;
          float: left;
          width: calc(#{$spinner-width}/2);
          height: calc(#{$spinner-height}/2);
          @media #{$mobile-device}{
            width: calc(#{$spinner-width-mob}/2);
            height: calc(#{$spinner-height-mob}/2);
          }
          & .vector2, & .vector21 {
            border-width: 0 0 calc(#{$spinner-width}/2) calc(#{$spinner-height}/2);
            @media #{$mobile-device}{
              border-width: 0 0 calc(#{$spinner-width-mob}/2) calc(#{$spinner-height-mob}/2);
            }
            border-style: solid;
            width: 0;
            height: 0;
            position: absolute;
          }

          &.group57 {
            transform: rotate(90deg);
            & .vector2 {
              border-color: transparent transparent #4575b4 transparent;
            }
            & .vector21 {
              border-color: #74ADD1 transparent transparent transparent;
              border-width: calc(#{$spinner-width}/2) calc(#{$spinner-height}/2) 0 0;
              @media #{$mobile-device}{
                border-width: calc(#{$spinner-width-mob}/2) calc(#{$spinner-height-mob}/2) 0 0;
              }
            }
            & .yes {
              transform: rotate(-22.5deg);
              left: 20%;
              top: -5%;
              @media #{$mobile-device}{
                left: 40px;
                top: -20px;
              }
            }

            & .no {
              transform: rotate(-67.5deg);
              top: 35%;
              right: 15%;
              @media #{$mobile-device}{
                top: 50px;
                right: 20px;
              }
            }
          }

          &.group571 {
            & .vector2 {
              border-color: transparent transparent #F46D43 transparent;
            }
            & .vector21 {
              border-color: transparent transparent #D73027 transparent;
              transform: rotate(180deg);
            }
            & .no {
              transform: rotate(-67.5deg);
              left: 10%;
              top: 5%;
              @media #{$mobile-device}{
                left: 15px;
                top: 0;
              }
            }

            & .yes {
              transform: rotate(-22.5deg);
              top: 40%;
              right: 25%;
              @media #{$mobile-device}{
                top: 60px;
                right: 45px;
              }
            }
          }
          &.group572 {
            & .vector2 {
              border-color: transparent transparent #E0F3F8 transparent;
            }
            & .vector21 {
              border-color: transparent transparent #ABD9E9 transparent;
              transform: rotate(180deg);
            }
            & .yes {
              transform: rotate(-22.5deg);
              left: 20%;
              top: -10%;
              @media #{$mobile-device}{
                left: 40px;
                top: -20px;
              }
            }

            & .no {
              transform: rotate(-67.5deg);
              top: 25%;
              right: 10%;
              @media #{$mobile-device}{
                top: 40px;
                right: 20px;
              }
            }
            & .probably {
              transform: rotate(-22.5deg);
              left: 15%;
              font-size: 22px;
              @media #{$mobile-device}{
                top: -10px;
                font-size: 16px;
              }
            }
          }

          &.group573 {
            transform: rotate(90deg);
            & .vector2 {
              border-color: transparent transparent #FEE090 transparent;
            }
            & .vector21 {
              border-color: #FDAE61 transparent transparent transparent;
              border-width: calc(#{$spinner-width}/2) calc(#{$spinner-height}/2) 0 0;
              @media #{$mobile-device}{
                border-width: calc(#{$spinner-width-mob}/2) calc(#{$spinner-height-mob}/2) 0 0;
              }
            }

            & .no {
              transform: rotate(-67.5deg);
              left: 5%;
              top: 10%;
              @media #{$mobile-device}{
                left: 15px;
                top: 10px;
              }
            }

            & .yes {
              transform: rotate(-22.5deg);
              top: 40%;
              right: 30%;
              @media #{$mobile-device}{
                top: 60px;
                right: 55px;
              }
            }

            & .probably {
              transform: rotate(-67.5deg);
              top: 13%;
              font-size: 22px;
              @media #{$mobile-device}{
                top: 18px;
                left: 5px;
                font-size: 16px;
              }
            }
          }
          & .yes, & .no, & .probably {
            color: white;
            font-size: 40px;
            @media #{$mobile-device}{
              font-size: 30px;
            }
            position: absolute;
            margin-top: 40px;
          }
        }
      }
      & .ellipse3 {
        position: absolute;
        width: calc(#{$spinner-width}/2.5);
        height: calc(#{$spinner-height}/2.5);
        margin: calc(-#{$spinner-width}/5 + 10px);
        @media #{$mobile-device}{
          width: calc(#{$spinner-width-mob}/2.5);
          height: calc(#{$spinner-height-mob}/2.5);
          margin: calc(-#{$spinner-width-mob}/5 + 10px);
        }
        left: 50%;
        top: 50%;

        border: 5px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: $spinner-width;
        & .arrow {
          -webkit-touch-callout: none;
          user-select: none;
          & .ellipse, & .arrow2 {
            width: $spinner-btn-width;
            height: $spinner-btn-height;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: calc(-#{$spinner-btn-width}/2);
            margin-top: calc(-#{$spinner-btn-height}/2);
            -webkit-touch-callout: none;
            user-select: none;
            @media #{$mobile-device}{
              width: $spinner-btn-width-mob;
              height: $spinner-btn-height-mob;
              margin-left: calc(-#{$spinner-btn-width-mob}/2);
              margin-top: calc(-#{$spinner-btn-height-mob}/2);
            }
          }
          & .arrow2 {
            animation-duration: 2.2s;
            animation-timing-function: linear;
            cursor: pointer;
            -webkit-touch-callout: none;
            user-select: none;
            &::after {
              transform-origin: 50% -46px 0;
              content: '';
              position: absolute; /* Абсолютное позиционирование */
              left: 28px;
              top: -47px; /* Положение треугольника */
              border: 23px solid transparent; /* Прозрачные границы */
              border-bottom: 30px solid #C33029; /* Добавляем треугольник */
            }
          }
          & .ellipse {
            background: #FF4941;
            border: 8px solid #C33029;
            box-sizing: border-box;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
            border-radius: 100px;
            text-align: center;
            vertical-align: middle;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

#btn-speed{
  width: 354px;
  height: 16.39px;
  @media #{$mobile-device}{
    width: 345px;
  }
  @media #{$desktop-device}{
    width: 16.39px;
    height: $spinner-height;
    margin: 0;
    display: flex;
    position: absolute;
    top: 5px;
  }
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid #A6A6A6;
  overflow: hidden;
  margin: 40px auto;
  & .mask{
    height: 16.39px;
    @media #{$tablet-device}{
      height: 16.39px !important;
    }
    @media #{$mobile-device}{
      height: 16.39px !important;
    }
    @media #{$desktop-device}{
      width: 16.39px !important;
      height: 500px;
      align-self: flex-end;
      display: flex;
    }
    overflow: hidden;
    width: 0;
    & .line{
      width: $spinner-height-mob;
      height: 16.39px;
      background: linear-gradient(90deg, #1CCB19 0%, #D5EB4F 35.14%, #FF7A00 68.59%, #FF0000 100%);
      @media #{$desktop-device}{
        width: 16.39px;
        height: $spinner-height;
        background: linear-gradient(0deg, #1CCB19 0%, #D5EB4F 35.14%, #FF7A00 68.59%, #FF0000 100%);
        align-self: flex-end;
      }
    }
  }
}

#btn-reset, .taro_bot_btn{
  width: 231px;
  height: 43px;
  margin: 30px auto;
  display: block;
  color: white;
  border: none;
  cursor: pointer;
  background: linear-gradient(163.79deg, #63C813 27.18%, #62B124 69.52%);
  box-shadow: 0 4px 10px rgba(96, 184, 26, 0.75);
  border-radius: 8px;
  -webkit-touch-callout: none;
  user-select: none;
}
.taro_bot_btn {
  padding-top: 5px;
  text-decoration: none;
  & div{
    display: flex;
    justify-content: center;
    & img{
      width: 30px !important;
      margin: 0 10px;
    }
  }
  &:hover{
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
}